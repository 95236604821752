@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}

body{
  background: rgb(0, 0, 0);
}

h1, h4, p, a{
  color: rgb(255, 255, 255);
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(246, 255, 0);
  color: rgb(0, 0, 0);
  border: 1px solid #fff;
  font-weight: 600;
}
.btnr{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(246, 255, 0);
  color: rgb(0, 0, 0);
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light{
  background: transparent;
  color: #fff;
}

.btn:hover{
  background: rgba(212, 254, 0, 0.43);
  color: rgb(239, 255, 151);
  transition: 0.3s;
}
.btnr:hover{
  background: rgba(212, 254, 0, 0.43);
  color: rgb(239, 255, 151);
  transition: 0.3s;
}
.social :hover{
  transform: scale(0.8);
	transition: 0.5s;
}
